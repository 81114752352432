<template>
    <div class="BigBox">
        <!--背景星空特效 -->
        <canvas id="myCanvas"></canvas>
           <!-- 顶部导航栏 -->
    <!-- 顶部导航栏 -->
    <div class="SpecTopNav">
      <!-- 首页导航 -->
      <div class="SpecHomePage" @click="Home()">
        <img src="../../assets/ConstructionLandscape/Home.png" alt="" style="width: 100%; height: 100%" />
      </div>
      <!-- 导航中间 -->
      <div class="SpecMiddle">
        <div class="ShopBj"></div>
        <div class="SpecHeadline">随心购物</div>
        <div class="ShopBjOne"></div>
      </div>
      <!-- 导航栏右边 -->
      <div class="SpecNavRight">
        <!-- 个人中心导航 -->
        <div class="SpecPersonage" @click="Person()">
          <img src="../../assets/CulturalHomePage/personal.png" alt="" style="width: 100%; height: 100%" />
        </div>
      </div>
    </div>
    <!--面包屑 -->
      <div class="BazCrumbs">
        <span style="cursor:pointer;" @click="DjHomePage()">随心购物首页</span>
        <span style="margin-left:1.2vh;">></span>
        <span style="margin-left:1.2vh;">随心购物详情</span>
      </div>
        <!--头部 -->
        <!-- <div class="ShopTop"> -->
        <!--头部中间 -->
        <!-- <div class="ShopMiddle"> -->
        <!-- 公告牌 -->
        <!-- <div class="ShopBillboard"> -->
        <!-- <img
            src="../../assets/ShopAtWill/Billboard.png"
            alt=""
            style="width: 100%; height: 100%"
          /> -->
        <!-- </div> -->
        <!-- 人物 -->
        <!-- <div class="ShopFigure"> -->
        <!-- 女孩子 -->
        <!-- <div class="ShopGirl"> -->
        <!-- <img
              src="../../assets/ShopAtWill/girl.png"
              alt=""
              style="width: 100%; height: 100%"
            /> -->
        <!-- </div> -->
        <!-- 公告语 -->
        <!-- <div class="ShopBillboardOne"><span>商品详情</span></div> -->
        <!-- 男孩子 -->
        <!-- <div class="ShopSchoolboy"> -->
        <!-- <img
              src="../../assets/ShopAtWill/schoolboy.png"
              alt=""
              style="width: 100%; height: 100%"
            /> -->
        <!-- </div>
        </div>
      </div>
    </div> -->
        <!-- <div>
            <img src="../../assets/CulturalCreation/Bazaar/banner.png" style="width: 100%;height: 100%;">
        </div> -->
        <!-- <div style="width: 80%;height: 1vh;margin: 1% auto;color: rgba(68, 68, 68, 1);font-size: 1vw;">
            <span style="cursor:pointer" @click="WC()">文创集市</span>
            <span style="margin:0 1%;">
                <img src="../../assets/CulturalCreation/Bazaar/rights.png" style="width: 0.8%;height: 0.7vw;">
            </span>
            <span style="cursor:pointer">商品详情</span>
        </div> -->
        <div style="width:100%;z-index:999;">
            <div class="Details">
                <div class="List">
                    <div class="ProductChart">

                        <div style="width:100%;height:45vh;margin:0 auto;border: 1px dashed rgba(221, 221, 221, 1);">
                            <img :src="details.logoUrl" style="width: 100%;height: 100%;" class="ImgOne">
                        </div>
                        <div style="width:100%;display:flex;">
                            <div class="Chart" v-for="(item, index) in ImgsOne" :key="index">
                                <img :src="item" class="Img" @mouseenter="qiehuanOne(item)">
                            </div>
                            <!-- <div class="Chart">
                            <img src="../../assets/CulturalCreation/Bazaar/small1.png" class="Img" @mouseenter="qiehuanOne(item,1)">
                        </div>
                        <div class="Chart">
                            <img src="../../assets/CulturalCreation/Bazaar/small2.png" class="Img" @mouseenter="qiehuanOne(item,2)">
                        </div> -->
                        </div>

                        <div style="width: 75%;height:4%;display: flex;font-size: 1.8vh;margin-top:1vh;">
                            <div style="margin:0 3%;width:20%;height: 100%;display: flex;">
                                <!-- ../../assets/CulturalCreation/Bazaar/live.png -->
                                <div style="width:30%;height:100%;">
                                    <img v-if="ShowImg" src="../../assets/CulturalCreation/Bazaar/live.png"
                                        style="width: 100%;height: 100%;" id="pic" @click="changeImage()">
                                    <img v-if="ShowImgOne" src="../../assets/CulturalCreation/Bazaar/loveOne.png" alt=""
                                        style="width:100%;height:100%;" @click="LayFiv()">
                                </div>
                                <div class="live-count" style="color:rgba(255, 255, 255, 1);font-size:2vh;">
                                    <span style="margin-left:1.5vh;">2K</span>
                                </div>

                            </div>
                            <div style="margin:0 3%;width:20%;height: 100%;display: flex;">
                                <div style="width:27%;height:100%;">
                                    <img v-if="ShowImgTwo" src="../../assets/CulturalCreation/Bazaar/shoucang.png"
                                        style="width: 100%;height: 100%;" @click="LayFivOne()">
                                    <img v-if="ShowImgTher" src="../../assets/CulturalCreation/Bazaar/heart.png" alt=""
                                        style="width:100%;height:100%;" @click="LayFivTwo()">
                                </div>
                                <div class="live-count" style="color:rgba(255, 255, 255, 1); font-size:2vh;">
                                    <span style="margin-left:1.5vh;">2K</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="Introduction">
                        <div style="width: 70%;">
                            <p style="color:rgba(255, 255, 255, 1); font-size: 2vw;margin-top:3%;">{{ details.title }}</p>
                            <p style="font-size: 1vw;margin: 2% 0;color: rgba(221, 221, 221, 1);">{{ details.introduction }}
                            </p>
                        </div>
                        <div style="margin: 2% 0;">
                            <span style="margin-right: 4%;color: rgba(255, 255, 255, 1);">价格</span>
                            <span class="BtnOne"
                                style="color:rgba(150, 192, 255, 1); font-size: 1.3vw;">￥{{ details.salesPrice / 100 * item.num }}</span>
                        </div>
                        <div style="margin: 2% 0;">
                            <span style="margin-right: 4%;color: rgba(255, 255, 255, 1);">数量</span>
                            <button class="btn" @click="reduce()"
                                style="width: 3%;height:100%;background-color:rgba(190, 190, 190, 1);">-</button>
                            <input @input="handleInput" class="count" type="text"
                                style="width: 3%;height: 90%;text-align: center;" v-model="item.num">
                            <button class="btn" @click="addition()"
                                style="width: 3%;height: 100%;background-color:rgba(190, 190, 190, 1);">+</button>
                        </div>
                        <div style="margin-top: 10%;height: 20%;">
                            <!-- <button @click="join()"  style="font-size: 20px;width: 25%;height: 60%;margin-right: 10%;color: white;border: none;background-color: rgba(185, 213, 255, 1);cursor: pointer;">加入购物车</button> -->
                            <button @click="Order()"
                                style="font-size: 20px;width: 25%;height: 60%;border: none;color: white;background-color: rgba(100, 162, 255, 1);cursor: pointer;">立即购买</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="commodity"> <span style="margin-left:2%;">商品详情</span> </div>
            <!-- 商品名称..... -->
            <div class="commodityOne">
                <div class="BazFlex">
                    <div class="editor" v-html="details.description"></div>

                </div>

            </div>

            <!-- 下面 -->
            <!-- <div class="BazButtom"> -->
            <!-- 左边 -->
            <!-- <div class="BazLeft"> -->
            <!-- 云 -->
            <!-- <div class="BazCloud"> <img src="../../assets/ShopAtWill/cloud.png" alt="" style="width:100%;height:100%;"> </div> -->
            <!-- 彩云 -->
            <!-- <div class="BazIridescent"> <img src="../../assets/ShopAtWill/IridescentTwo.png" alt="" style="width:100%;height:100%;"> </div> -->
            <!-- 云 -->
            <!-- <div class="BazCloudOne"> <img src="../../assets/ShopAtWill/cloud.png" alt="" style="width:100%;height:100%;"> </div>
            </div> -->
            <!-- 中间图片 -->
            <!-- <div style="width:85%;height:100%;border: 1px dashed;z-index:99;">
                <img src="../../assets/CulturalCreation/Bazaar/bg.png" style="height: 100%;width: 100%;">
            </div> -->
            <!-- 右边 -->
            <!-- <div class="BazRight"> -->
            <!-- 云 -->
            <!-- <div class="BazCloudTwo"> <img src="../../assets/ShopAtWill/cloud.png" alt="" style="width:100%;height:100%;"> </div> -->
            <!-- 彩云 -->
            <!-- <div class="BazIridescentOne"> <img src="../../assets/ShopAtWill/IridescentOne.png" alt="" style="width:100%;height:100%;"> </div> -->
            <!-- 云 -->
            <!-- <div class="BazCloudTher"> <img src="../../assets/ShopAtWill/CloudTwo.png" alt="" style="width:100%;height:100%;"> </div> -->
            <!-- 彩云 -->
            <!-- <div class="BazIridescentTwo"> <img src="../../assets/ShopAtWill/iridescent.png" alt="" style="width:100%;height:100%;"> </div>
            </div>
            </div> -->
            <Bottom_BarOne style="z-index:999;"></Bottom_BarOne>
            <BackTop style="z-index:999;"></BackTop>
        </div>
        <!-- <Navigation_Buttons></Navigation_Buttons> -->

    </div>
</template>

<script>
import Bottom_BarOne from '../../components/Public/Bottom_BarOne';
import BackTop from "../../assets/BackTop/BackTop.vue";
// let flags =[]Navigation_Buttons
export default {
    components: { Bottom_BarOne,BackTop },
    data() {
        return {
            item: {
                num: 1
            },
            //图片
            imgs: [],
            // 商品详情
            details: {
                imgs: [],
               },
                
            //点赞
            ShowImg: true,
            ShowImgOne: false,
            objectId: '',
            objectType: 2,
            ObjectTypeOne: 2,
            // 收藏
            ShowImgTwo: true,
            ShowImgTher: false,
            form: {
                img: '',
                title: '',
                jiage: '',
                shuliang: '',
                soldNum: '',
                categoryName: '',
                imgs:[],
                description:'',
            },
            one: {},
            two: {},
            ImgsOne:[],
        }
    },
    //     created() {
    //   this.livecount = [13,4224,5345,312,153,593,1323,3465]

    // },
    created() {
        // 商品详情
        // this.OrderFormBazz()
        // this.details = this.$store.state.details
    },
    mounted() {
        this.GetShopList()
        this.XingKong()
       
        
    },
    methods: {
          // 返回个人中心
    Person() {
        // 进入页面就判断是否有token  如果没有那就继续登陆 如果有就进入个人中心
        if(this.$cookies.get("token")==null){
            this.$router.push('/login')
        }else{
            this.$router.push('/personalCenter')
        }
    },
    // 返回首页
    Home() {
      this.$router.push('/');
    },
    //点击返回购物首页
    DjHomePage(){
        this.$router.push('/ShopAtWillHome');
    },
        //星空特效
        XingKong() {
            var myCanvas = document.getElementById('myCanvas');
            var ctx = myCanvas.getContext("2d");
            var starlist = [];
            function init() {
                // 设置canvas区域的范围为整个页面
                myCanvas.width = window.innerWidth;
                myCanvas.height = window.innerHeight;
            };
            init();
            // 监听屏幕大小改变 重新为canvas大小赋值
            // window.onresize = init;

            var list = [];
            // 将页面上每一个小圆点的信息存在list数组中
            // 数组中的每一位是一个对象  对象中存着每一个小点的信息
            // 利用构造函数生成数组

            function Star(x, y, radius, disX, disY) {
                // 将传入的参数设置为对象的属性
                // 位置坐标
                this.x = x;
                this.y = y;
                // 半径
                this.radius = radius;
                // 变化距离
                this.disX = disX;
                this.disY = disY;
            }

            for (var i = 0; i < 800; i++) {
                // 设置位随机值
                var x = Math.random() * myCanvas.width;
                var y = Math.random() * myCanvas.height;
                var radius = Math.random() * 2;
                var disX = x - myCanvas.width / 2;
                var disY = y - myCanvas.height / 2;
                // 每一个的信息存在对象中  所有小点存在list数组中
                list.push(new Star(x, y, radius, disX, disY));
            }

            // 绘制并且运动函数
            function animate() {
                // 在每次渲染前清除一下屏幕
                ctx.clearRect(0, 0, myCanvas.width, myCanvas.height);
                // 下一次渲染  根据存放在数组中的信息进行画小星星
                for (var i = 0; i < 800; i++) {
                    // 取出每一个信息点信息
                    var a = list[i];

                    // a的坐标在改变  根据disx disy进行改变  /50改变值设置的小一点
                    a.x += a.disX / 50;
                    a.y += a.disY / 50;

                    // 如果小圆点运动到边界之外
                    if (a.x < 0 || a.y < 0 || a.x > myCanvas.width || a.y > myCanvas.height) {
                        // 重新出现一个小星星的位置
                        a.x = Math.random() * myCanvas.width;
                        a.y = Math.random() * myCanvas.height;
                        // 同时根据 当前新出现的位置设置移动距离
                        a.disX = a.x - myCanvas.width / 2;
                        a.disY = a.y - myCanvas.height / 2;
                    }

                    // 开始画每一个点
                    ctx.beginPath();
                    // 设置填充颜色
                    ctx.fillStyle = "#00F5FF";
                    // 每一小星星为一个小圆
                    ctx.arc(a.x, a.y, a.radius, 0, Math.PI * 2, false);
                    // 进行填充
                    ctx.fill();
                }
                // 不断绘制canvas  根据disx与disy的位置变化  更新坐标  视觉上位运动状态
                setTimeout(animate, 60);
            }
            animate();
        },
        // 只可以输入正整数
        handleInput(e) {
            this.item.num = e.target.value.replace(/\D/g, '').replace(/^0{1,}/g, '');
            if (this.item.num.length < 1) {
                this.item.num = 1
            }
        },
        //数量减
        reduce() {
            //判断 books的数量是否 <= 1 ,弹出提示框，不能再减了
            if (this.item.num <= 1) {
                // alert("不能在减了")
                //否则 (大于1的时候) --
            } else {
                this.item.num--
            }
        },
        //数量加
        addition() {
            //数量可以一直无限的++
            this.item.num++
        },

        qiehuanOne(item) {
            this.details.logoUrl = item
        },

        Order() {
            this.form.img = this.details.logoUrl
            this.form.jiage = this.details.salesPrice
            this.form.title = this.details.title
            this.form.shuliang = this.details.introduction
            this.form.id = this.details.goodsId
            this.form.categoryName = this.details.categoryName
            this.form.soldNum = this.item.num 
            this.form.imgs = this.details.imgs 
            this.form.description =this.details.description
            this.$router.push({
                path: '/order',
                query:
                    this.form

            });
        },
        // 商品详情
        GetShopList() {
            var id = this.$route.query.id 
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/goods/detail/' + id,
                // params:{
                //     GoodsCodeid:this.GoodsCodeid
                // }
                // headers: {
                //              Authorization : 'Bearer ' + this.$cookies.get("token"), 
                //              'Content-Type': 'application/json'
                //   },
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.details = res.data.data
                        // this.imgs = res.data.data.imgs
                        // 如果有五张图片就五张放进去如果没有就按长度放
                        if (res.data.data.imgs.length > 4) {
                            for (let i = 0;  i<5; i++) {
                            this.ImgsOne.push(res.data.data.imgs[i])
                            // this.details.imgs[i] = res.data.data.imgs[i] 
                            //  this.details.imgs.push(res.data.data.imgs[i])
                         }
                        } else {
                            for (let i = 0;  i<res.data.data.imgs.length ; i++) {
                            this.ImgsOne.push(res.data.data.imgs[i])
                            // this.details.imgs[i] = res.data.data.imgs[i]
                            // this.details.imgs.push(res.data.data.imgs[i])
                         }
                        }
                       
                        this.details.description = this.details.description.replace(/\<img/gi, '<img style="height:auto;margin:0 auto; display:block;"  ')
                        
                    }
                })
                
                .catch(function (error) {
                });
        },
         // 商品订单信息
    //  OrderFormBazz(){
    //   this.details = this.$route.query;
    //   this.ImgsOne=this.$route.query;
    //  //   this.ImgsOne = this.$route.params.ImgsOne;
    //  console.log('图片', this.$route.query);
    //  //   this.ImgsOne = this.details.imgs
    
    // },
        // 加入购物车
        // join(){
        //     var axios = require('axios');
        //     var config = {
        //         method: 'post',
        //         url:  this.$Schttp+'/vtp/app/cart/addCart',
        //         // params:{
        //         //     GoodsCodeid:this.GoodsCodeid
        //         // }
        //         headers: {
        //                      Authorization : 'Bearer ' + this.$cookies.get("token"), 
        //                      'Content-Type': 'application/json'
        //           },
        //          data:{
        //             goodsId:this.details.goodsId,
        //             productNum:this.item.num,

        //          }
        //     };
        //     axios(config)
        //         .then((res) => {
        //             if (res.data.code == 200) {
        //                 this.$message({
        //                 message:'添加成功',
        //                 type: 'success'
        //               });
        //             } 
        //             if (res.data.code == 401) {
        //                 this.$message({
        //                 message:'请先登录',
        //                 type: 'success'
        //               });
        //                 this.$router.push('/login');
        //             }  
        //         })
        //         .catch(function (error) {
        //         });
        // },

        // 点赞
        changeImage() {
            this.ShowImg = false;
            this.ShowImgOne = true;
            var axios = require('axios');
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/customerLikes/addLikes',
                // params:{
                //     GoodsCodeid:this.GoodsCodeid
                // }
                headers: {
                    Authorization: 'Bearer ' + this.$cookies.get("token"),
                    'Content-Type': 'application/json'
                },
                data: {
                    objectId: this.details.goodsId,
                    objectType: this.objectType,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {

                    }
                    // 点击跳转登录
                    if (res.data.code == 401) {
                        this.$router.push('/Login');
                    }

                })
                .catch(function (error) {
                });
        },
        // 取消点赞
        LayFiv() {
            this.ShowImg = true;
            this.ShowImgOne = false;
            var axios = require('axios');
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/customerLikes/cancelLikes',
                // params:{
                //     GoodsCodeid:this.GoodsCodeid
                // }
                headers: {
                    Authorization: 'Bearer ' + this.$cookies.get("token"),
                    'Content-Type': 'application/json'
                },
                data: {
                    objectId: this.details.goodsId,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {

                    }
                    // 点击跳转登录
                    if (res.data.code == 401) {
                        this.$router.push('/Login');
                    }
                })
                .catch(function (error) {
                });
        },
        //点击收藏
        LayFivOne() {
            this.ShowImgTwo = false;
            this.ShowImgTher = true;
            var axios = require('axios');
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/customerCollection/addCollection',
                // params:{
                //     GoodsCodeid:this.GoodsCodeid
                // }
                headers: {
                    Authorization: 'Bearer ' + this.$cookies.get("token"),
                    'Content-Type': 'application/json'
                },
                data: {
                    objectId: this.details.goodsId,

                    objectType: this.ObjectTypeOne,

                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {

                    }
                    // 点击跳转登录
                    if (res.data.code == 401) {
                        this.$router.push('/Login');
                    }

                })
                .catch(function (error) {
                });

        },
        //点击取消收藏
        LayFivTwo() {
            this.ShowImgTwo = true;
            this.ShowImgTher = false;
            var axios = require('axios');
            var config = {
                method: 'post',
                url: this.$Schttp + '/vtp/app/customerCollection/cancelCollection',
                // params:{
                //     GoodsCodeid:this.GoodsCodeid
                // }
                headers: {
                    Authorization: 'Bearer ' + this.$cookies.get("token"),
                    'Content-Type': 'application/json'
                },
                data: {
                    objectId: this.details.goodsId,
                    // objectType:this.ObjectTypeFrom.objectType,
                }
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {

                    }
                    // 点击跳转登录
                    if (res.data.code == 401) {
                        this.$router.push('/Login');
                    }

                })
                .catch(function (error) {
                });
        }
    }

}
</script>

<style lang="less" scoped>@import url(./Bazaar.less);</style>